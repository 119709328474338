<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="40"
      >
        <v-toolbar-title>Branch Defaults</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/inventory/item-defaults/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>Create Item Defaults
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="8">
            <v-data-table
              :headers="headers"
              :items="userDefaults"
            >
              <template v-slot:item.Name="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/inventory/item-defaults/${item.id}`"
                >{{ item.Name }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search: "",
    record: {},
    userDefaults: [],
    headers: [
      { text: "Code", value: "Code" },
      { text: "Name", value: "Name" },
    ],
  }),
  methods: {
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/item-defaults`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.userDefaults = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>